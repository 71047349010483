import { ReactComponent as LocationIcon } from 'icons/custom/location.svg';
import { propertySVGs } from '../../../../utils/helpers';
import DataItem from '../../Properties/Components/DataItem';
import { ReactComponent as HeartSolid } from 'icons/custom/heart-solid.svg';
import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
import InfoAsterisk from '../../Dashboard/Components/InfoAsterisk';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

function PropertyCard({ property, toggleFavourite }) {
  return (
    <>
      <div className="card project-boxed rounded-0" style={{ border: '1px solid #F0F0F0' }}>
        <div className="absolute flex flex-col gap-2 top-6 right-4">
          <div className="bg-white w-8 h-8 rounded d-flex items-center content-center justify-center shadow-xl">
            <div className="cursor-pointer" onClick={() => toggleFavourite(property)}>
              <InfoAsterisk text={`Remove from favourites`} icon={<HeartSolid className="w-5" />} />
            </div>
          </div>
        </div>

        <div className="flex justify-center mb-4 h-48">
          {property.property_images?.length ? (
            <div className="h-48 w-100">
              <img src={property.property_images[0]} alt="" className="w-100 h-100 object-cover" />
            </div>
          ) : (
            <div className="pt-4">
              {property.property_type ? propertySVGs[property.property_type]?.component : propertySVGs['F']?.component}
            </div>
          )}
        </div>
        <div className="flex mx-3	pb-3 mb-6" style={{ borderBottom: '1px solid #F0F0F0' }}>
          <LocationIcon />
          <div className="font-medium text-base text-black ml-2.5 truncate pt-0.5" title={property.address}>
            {property.address}
          </div>
        </div>
        <div className="p-0 pb-3">
          <div className="px-2 grid grid-cols-2 gap-x-8 ">
            <DataItem label={'Property Size (sq ft)'} value={property.size ? property.size : '-'} />
            <DataItem label={'Price (£)'} value={property.price ? numeral(property.price).format() : '-'} />
            <DataItem label={'Date'} value={property.date ? property.date : '-'} />
            <DataItem
              label={'Type'}
              value={
                property.dataset
                  ? property.dataset === 'LS'
                    ? 'Listing'
                    : property.dataset === 'LR'
                      ? 'Transaction'
                      : '-'
                  : '-'
              }
            />
            <DataItem
              label={'New Build'}
              value={property.old_new_lr ? (property.old_new_lr === 'N' ? 'No' : 'Yes') : '-'}
            />
            <DataItem label={'Bathrooms'} value={property.bathrooms ? parseInt(property.bathrooms) : '-'} />
            <DataItem
              label={'Tenure'}
              value={
                property.freehold_leasehold ? (property.freehold_leasehold === 'F' ? 'Freehold' : 'Leasehold') : '-'
              }
            />
            <DataItem
              label={'EPC rating'}
              value={
                property.current_energy_rating_epc
                  ? property.current_energy_rating_epc !== 'None'
                    ? property.current_energy_rating_epc
                    : '-'
                  : '-'
              }
            />
            <DataItem label={'Distance Radius'} value={property.distance_from ? property.distance_from : '-'} />
          </div>
        </div>
      </div>
    </>
  );
}

export default PropertyCard;
