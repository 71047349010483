import { useCallback, useEffect, useState } from 'react';
import RangeSliderItem from '../../Dashboard/Components/RangeSliderItem';
import debounce from 'lodash.debounce';

const FilterListings = ({ updateFilter }) => {
  const [distanceRange, setDistanceRange] = useState([0, 10]);

  useEffect(() => {
    const ranges = {
      min_distance: distanceRange[0],
      max_distance: distanceRange[1],
    };
    debouncedChangeHandler({ ...ranges });
  }, [distanceRange]); // eslint-disable-line react-hooks/exhaustive-deps

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useCallback(
    debounce((params) => updateFilter(params), 200),
    [],
  );

  return (
    <>
      <div className="p-2">
        <h3 className="mb-2"> Distance to property (km) </h3>
        <RangeSliderItem
          title={''}
          min={0}
          max={10}
          step={0.1}
          range={distanceRange}
          updateRange={setDistanceRange}
          unit={'km'}
          unitPlacement={'left'}
        ></RangeSliderItem>
      </div>
    </>
  );
};

export default FilterListings;
