import axiosInstance from '../services/AxiosInstance';
import Cookies from 'js-cookie';

export function getSuperIndexByParams(postTown, districtCode, propertyType, numBedrooms) {
  return axiosInstance.get(`superindices/${postTown}/${districtCode}/${propertyType}/${numBedrooms}`, {
    headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
  });
}

export function searchSuperIndicesData(data, cancelToken = {}) {
  return axiosInstance.post(
    `/superindices/`,
    { ...data, max_rental_yield: data.max_rental_yield || 0.09 },
    {
      ...cancelToken,
      headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
    },
  );
}

export const searchSuperIndicesCSV = async (data, cancelToken = {}) => {
  try {
    const response = await axiosInstance.post(
      `/superindices/`,
      { ...data, max_rental_yield: data.max_rental_yield || 0.09 },
      {
        ...cancelToken,
        headers: {
          Authorization: `Bearer ${Cookies.get('REalysepmt-token')}`,
          Accept: 'text/csv',
        },
      },
    );
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('get super index CSV failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
