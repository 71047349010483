import { ReactComponent as AnalyticsIcon } from 'icons/custom/analytics.svg';
import { ReactComponent as LocationIcon } from 'icons/custom/location.svg';
import { ReactComponent as MenuDots } from 'icons/custom/menu-dots.svg';
import { ReactComponent as DeleteSVG } from 'icons/custom/delete.svg';
import { ReactComponent as HeartSolid } from 'icons/custom/heart-solid.svg';
import { ReactComponent as HeartRegular } from 'icons/custom/heart-regular.svg';
import { ReactComponent as Info } from 'icons/custom/info-icon.svg';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';

import { Link } from 'react-router-dom';

import { propertySVGs } from '../../../../utils/helpers';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';

import SecondaryButton from '../../Dashboard/Buttons/SecondaryButton';
import InfoAsterisk from '../../Dashboard/Components/InfoAsterisk';

import DataItem from './DataItem';

import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
import moment from 'moment';
import { apiGetPropertyAnalytics } from '../../../../api/property';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { renderAccuracyInfo } from '../../Property/Helpers/Helpers';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

function PropertyCard({ propertyData, handleClickDelete, toggleFavourite, favouritePropertyIds }) {
  const favourite = favouritePropertyIds.includes(propertyData.id);
  const [property, setProperty] = useState(propertyData);

  const { data } = useQuery(
    ['property-analytics', propertyData.id],
    ({ signal }) => apiGetPropertyAnalytics(propertyData.id, signal),
    {
      enabled: propertyData.status === 'In progress',
      refetchIntervalInBackground: true,
      refetchInterval: (data) => {
        if (data?.status !== 'In progress') return false;
        return 30000;
      },
      onSuccess: (newData) => {
        if (newData?.status !== 'In progress') {
          setProperty(newData);
        }
      },
    },
  );

  return (
    <>
      <div className="col-xl-3 col-xxl-4 col-lg-6 col-md-6 col-sm-6">
        <div className="card project-boxed">
          <div className="absolute flex flex-col gap-2 top-6 right-4">
            <div className="bg-white w-8 h-8 rounded d-flex items-center content-center justify-center shadow-xl">
              <Menu
                align={'end'}
                transition
                menuButton={
                  <MenuButton className="h-6 flex items-center justify-end">
                    <MenuDots />
                  </MenuButton>
                }
              >
                <MenuItem
                  className="flex items-center justify-start pl-3 text-xs font-semibold"
                  style={{ color: '#747B8A' }}
                  onClick={() => handleClickDelete(property.id)}
                >
                  <DeleteSVG className="mr-2" /> <span className="mt-0.5">Delete</span>
                </MenuItem>
              </Menu>
            </div>

            <div className="bg-white w-8 h-8 rounded d-flex items-center content-center justify-center shadow-xl">
              <div className="cursor-pointer" onClick={() => toggleFavourite(property, favourite)}>
                {favourite ? (
                  <InfoAsterisk text={`Remove from favourites`} icon={<HeartSolid className="w-5" />} />
                ) : (
                  <InfoAsterisk text={`Add to favourites`} icon={<HeartRegular className="w-5" />} />
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-center mb-4 h-48">
            {property.property_images?.length ? (
              <img src={property.property_images[0]} alt="" className="h-full w-full object-cover" />
            ) : (
              // TODO: handle properties without property_type
              <div className="pt-4">
                {property.property_type
                  ? propertySVGs[property.property_type]?.component
                  : propertySVGs['F']?.component}
              </div>
            )}

            <div className="absolute flex flex-col gap-2 top-6 left-4">
              <div className="bg-white w-8 h-8 rounded d-flex items-center content-center justify-center shadow-xl">
                {property.property_images?.length ? (
                  <InfoAsterisk
                    text={`Property images available`}
                    icon={
                      <i className="fa fa-images cursor-pointer" aria-hidden="true" style={{ color: '#3571e5' }}></i>
                    }
                  />
                ) : (
                  <i className="fa fa-images cursor-not-allowed text-gray-300" aria-hidden="true"></i>
                )}
              </div>
              <div className="bg-white w-8 h-8 rounded d-flex items-center content-center justify-center shadow-xl">
                {property.floor_plans?.length ? (
                  <InfoAsterisk
                    text={`Property floor plans available`}
                    icon={<i className="fa fa-home cursor-pointer" aria-hidden="true" style={{ color: '#3571e5' }}></i>}
                  />
                ) : (
                  <i className="fa fa-home cursor-not-allowed text-gray-300" aria-hidden="true"></i>
                )}
              </div>
            </div>
            {property.parent_id ? (
              <div
                className="absolute flex top-36 right-4 rounded-md px-2 py-2 text-xs"
                style={{
                  background: property.property_images?.length ? '#fff' : 'rgba(53, 113, 229, 0.15)',
                  color: '#3571E5',
                }}
              >
                {property.changed_number_of_bedrooms ? 'Cloned' : 'Revalued'}
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="flex mx-3	pb-3 mb-6" style={{ borderBottom: '1px solid #F0F0F0' }}>
            <LocationIcon />
            <div className="font-medium text-base text-black ml-2.5 truncate pt-0.5" title={property.address}>
              {property.address}
            </div>
          </div>
          <div className="p-0 pb-3">
            <div className="px-2 grid grid-cols-2 gap-x-8 ">
              <DataItem
                label={'Last Transaction Date'}
                value={
                  moment(property.property_index_data?.last_transaction_date).isBefore(moment()) ? (
                    property.property_index_data?.last_transaction_date?.split(/-/)?.reverse()?.join('/')
                  ) : (
                    <span className="italic opacity-50"> - </span>
                  )
                }
              />
              <DataItem
                label={'Last Transaction Price'}
                value={
                  property.property_index_data?.last_transaction_price > 0 ? (
                    numeral(property.property_index_data?.last_transaction_price).format()
                  ) : (
                    <span className="italic opacity-50"> - </span>
                  )
                }
              />
              <DataItem
                label={'Index AVM value'}
                value={
                  property.price ? numeral(property.price).format() : <span className="italic opacity-50"> - </span>
                }
                info={renderAccuracyInfo({ property })}
              />
              <DataItem
                label={'Average Listed Price'}
                value={
                  property.listed_average_price ? (
                    numeral(property.listed_average_price).format()
                  ) : (
                    <span className="italic opacity-50"> - </span>
                  )
                }
              />
              <DataItem
                label={'Property Type'}
                value={
                  property.status !== 'In progress' ||
                  (data && data?.status !== 'In progress') ||
                  (property && property.status === undefined) ||
                  (data && data.status === undefined) ? (
                    propertySVGs[property.property_type]?.name
                  ) : (
                    <span className="italic opacity-50"> - </span>
                  )
                }
              />
              <DataItem
                label={'Number Of Rooms'}
                value={
                  property.status !== 'In progress' ||
                  (data && data?.status !== 'In progress') ||
                  (property && property.status === undefined) ||
                  (data && data.status === undefined) ? (
                    property.num_rooms
                  ) : (
                    <span className="italic opacity-50"> - </span>
                  )
                }
              />
              <DataItem
                label={'Gross Yield'}
                value={
                  property.price && property.operating_income ? (
                    numeral(property.operating_income / property.price).format('0.00%')
                  ) : (
                    <span className="italic opacity-50"> - </span>
                  )
                }
              />
              <DataItem
                label={'Cap Rate'}
                value={
                  property.price ? (
                    numeral((property.operating_income / property.price) * 0.6).format('0.00%')
                  ) : (
                    <span className="italic opacity-50"> - </span>
                  )
                }
              />
              <DataItem
                label={'Rental AVM'}
                value={
                  property.operating_income ? (
                    numeral(property.operating_income / 12).format()
                  ) : (
                    <span className="italic opacity-50"> - </span>
                  )
                }
              />
              <DataItem
                label={'Monthly Expenses'}
                value={
                  property.operating_income ? (
                    numeral((property.operating_income / 12) * 0.4).format()
                  ) : (
                    <span className="italic opacity-50"> - </span>
                  )
                }
              />
            </div>

            <div className="w-full px-2 py-2 h-12 mb-2 mt-1">
              {property.parent_id ? (
                <div
                  style={{ backgroundColor: '#F2F6FB', fontSize: 10 }}
                  className="px-2 py-2 rounded-lg w-full flex items-center justify-start"
                >
                  <div className="flex items-center justify-start">
                    <Info className="w-4 mr-1" />
                    This property has been {property.changed_number_of_bedrooms ? 'cloned' : 'revalued'}
                  </div>
                  <Link
                    className="underline pl-3"
                    to={`/property-analytics/${property.parent_id}`}
                    style={{ color: '#3571E5' }}
                  >
                    See original property
                  </Link>
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="w-full flex px-2 pt-2">
              {property.status !== 'In progress' ||
              (data && data?.status !== 'In progress') ||
              (property && property.status === undefined) ||
              (data && data.status === undefined) ? (
                <Link className="ml-auto" to={`/property-analytics/${property.id}`}>
                  <SecondaryButton className="w-auto px-3 ml-auto">
                    <AnalyticsIcon />
                    <span className="pl-2.5">More information</span>
                  </SecondaryButton>
                </Link>
              ) : (
                <div className="w-auto d-flex ml-auto text-xs items-center h-10 px-2">
                  <LoaderSvg className="animate-spin h-5 w-5 text-white" />
                  <div className="ml-2">Valuation in Progress</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PropertyCard;
