import React, { useMemo, useState } from 'react';

import PropertyParameter from './PropertyParameter';
import InfoPopover from '../../Dashboard/Components/InfoPopover';
import { ReactComponent as LocationSVG } from 'icons/custom/location.svg';
import { ReactComponent as ArrowDownRoundIcon } from 'icons/custom/arrow-down-round.svg';
import { ReactComponent as ArrowUpRoundIcon } from 'icons/custom/arrow-up-round.svg';
import { ReactComponent as ChevronLeft } from 'icons/custom/chevron-left.svg';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
import ImageCarouselModal from '../../Dashboard/Modals/ImagesModal';
import SecondaryButton from '../../Dashboard/Buttons/SecondaryButton';
import PropertyTransactions from './PropertyTransactions';
import TransactionsModal from '../Modals/TransactionsModal';
import { getPropertyTransactions } from '../../../../api/comparables';
import { useQuery } from '@tanstack/react-query';
import { getPropertyParameters, getPropertyDemographicsParameters } from '../Helpers/Helpers';
import { getDistrictPlanningApplications } from '../../../../api/planning';
import RadialChart from './RadialChart';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const PropertyDetails = ({ property, demographicsData }) => {
  const [modalImages, setModalImages] = useState([]);
  const [showImageCarouselModal, setShowImageCarouselModal] = useState(false);
  const [showTransactionsModal, setShowTransactionsModal] = useState(false);

  const [expanded, setExpanded] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const propertyParameters = getPropertyParameters(property);

  const getCarouselProps = {
    showArrows: true,
    showThumbs: true,
    infiniteLoop: true,
    dynamicHeight: true,
    showStatus: false,
    showIndicators: true,
    swipeable: true,
    emulateTouch: true,
    renderArrowPrev: (clickHandler, hasPrev) => {
      return (
        <div
          className={`${
            hasPrev ? 'absolute' : 'hidden'
          } inset-y-1/2 left-0 flex justify-center items-center p-3 rounded-lg bg-white shadow-lg hover:opacity-80 cursor-pointer z-10 m-3 mt-0 h-10 w-10`}
          onClick={clickHandler}
        >
          <ChevronLeft className="w-6 h-6" />
        </div>
      );
    },
    renderArrowNext: (clickHandler, hasNext) => {
      return (
        <div
          className={`${
            hasNext ? 'absolute' : 'hidden'
          } inset-y-1/2 right-0 flex justify-center items-center p-3 rounded-lg bg-white shadow-lg hover:opacity-80 cursor-pointer z-10 m-3 mt-0 h-10 w-10`}
          onClick={clickHandler}
        >
          <ChevronLeft className="w-6 h-6" style={{ transform: 'rotate(180deg)' }} />
        </div>
      );
    },
  };

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  const openImagesModal = (images, index) => {
    setModalImages(images);
    setCurrentImageIndex(index);
    setShowImageCarouselModal(true);
  };

  const { data: transactionsData, isSuccess: transactionsLoaded } = useQuery(
    ['property-transactions', property.id, 'soldPrice'],
    ({ signal }) => getPropertyTransactions({ uprn: property.uprn, type: 'soldPrice' }, signal),
  );

  const { data: listingsData, isSuccess: listingsLoaded } = useQuery(
    ['property-transactions', property.id, 'askingPrice'],
    ({ signal }) => getPropertyTransactions({ uprn: property.uprn, type: 'askingPrice' }, signal),
  );

  const { data: planningApplicationsData, isLoading: planningApplicationsLoading } = useQuery(
    ['planning-applications', property.property_index_data.district_code],
    ({ signal }) => getDistrictPlanningApplications({ district: property.property_index_data.district_code }, signal),
    {
      enabled: !!property?.property_index_data?.district_code,
    },
  );

  const formattedTransactions = useMemo(() => {
    if (transactionsLoaded && listingsLoaded) {
      const listingsWithType = listingsData.data.map((item) => ({
        ...item,
        type: 'listing',
        sortingDate: item.date_appeared,
      }));

      const transactionsWithType = transactionsData.data.map((item) => ({
        ...item,
        type: 'transaction',
        sortingDate: item.date_sold,
      }));

      const sortedData = [...listingsWithType, ...transactionsWithType].sort((a, b) => {
        return new Date(b.sortingDate) - new Date(a.sortingDate);
      });

      for (let i = sortedData.length - 1; i > 0; i--) {
        const currentTransaction = sortedData[i];
        const previousTransaction = sortedData[i - 1];

        if (currentTransaction.bedrooms !== previousTransaction.bedrooms) {
          previousTransaction.modifiedRooms = true;
        }
      }

      return sortedData;
    } else {
      return [];
    }
  }, [transactionsLoaded, listingsLoaded, listingsData, transactionsData]);

  const propertyDemographicsParameters = getPropertyDemographicsParameters(demographicsData);

  return (
    <>
      <TransactionsModal
        property={property}
        transactions={formattedTransactions}
        setShowModal={setShowTransactionsModal}
        showModal={showTransactionsModal}
      />
      <ImageCarouselModal
        setShowModal={setShowImageCarouselModal}
        showModal={showImageCarouselModal}
        currentIndex={currentImageIndex}
        images={modalImages}
      ></ImageCarouselModal>
      <h4 className="mb-2.5 ml-2 fs-20 text-black"> Property details </h4>
      <div className="card p-4">
        <div className={`row h-${expanded ? 'full' : '72'} overflow-hidden`}>
          <div className="col-5"></div>
          <div className="col-12">
            <div className="flex flex-row">
              <div className="mr-3 w-4">
                <LocationSVG />
              </div>
              <div className="font-medium	text-2xl text-black pb-6">{property?.address}</div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-xl-8">
                <div className="row">
                  {propertyParameters.map((parameter, index) => (
                    <div key={index} className="col-lg-6 col-xl-4">
                      <PropertyParameter parameter={parameter} />
                    </div>
                  ))}

                  {propertyDemographicsParameters.map((parameter, index) => (
                    <div key={index} className="col-lg-6 col-xl-4">
                      <div className="font-medium	text-base	mb-2 flex" style={{ color: '#747B8A' }}>
                        <div className="mr-2">{parameter.name}</div>
                        <InfoPopover
                          placement="top"
                          title={parameter.popupTitle}
                          description={parameter.popupDescription}
                        />
                      </div>
                      <div
                        className="flex flex-row font-medium text-base text-black items-center pb-2 mb-3"
                        style={{ borderBottom: '1px solid #F0F0F0' }}
                      >
                        <div className="w-6	mr-2.5">{parameter.icon}</div>

                        {parameter.value}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="col-lg-6 col-xl-4 ">
                {formattedTransactions.length ? (
                  <div>
                    <PropertyTransactions
                      property={property}
                      transactions={formattedTransactions}
                      showModal={() => setShowTransactionsModal(true)}
                      modalView={false}
                    />
                  </div>
                ) : (
                  ''
                )}
                <div className="my-6">
                  {planningApplicationsLoading ? (
                    <LoaderSvg className="animate-spin h-8 w-8 text-white ml-4" />
                  ) : planningApplicationsData.count && planningApplicationsData.granted ? (
                    <div className="card p-1 pt-3" style={{ boxShadow: '0px 4px 10px 2px rgb(0 0 0 / 10%)' }}>
                      <h4 className="mb-2 text-base text-center fs-16" style={{ color: '#747B8A' }}>
                        Planning Applications Risk
                      </h4>

                      <div className="w-96 lg:w-80 xl:w-96 mx-auto">
                        <RadialChart
                          value={(
                            (1 - planningApplicationsData.granted / planningApplicationsData.count) *
                            100
                          ).toFixed()}
                        />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>

          {property?.property_images?.length ? (
            <div className="col-md-6 p-6">
              <h4 className="mb-4 text-base text-black"> Property Images </h4>
              <Carousel {...getCarouselProps}>
                {property?.property_images?.map((image, index) => {
                  return (
                    <div
                      key={index}
                      className="cursor-pointer"
                      onClick={() => openImagesModal(property?.property_images, index)}
                    >
                      <img src={image} alt="carousel" />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          ) : (
            ''
          )}

          {property?.floor_plans?.length ? (
            <div className="col-md-6 p-6">
              <h4 className="mb-4 text-base text-black "> Property Floor Plans </h4>
              <Carousel {...getCarouselProps} style={{ maxHeight: 300 }}>
                {property?.floor_plans?.map((image, index) => {
                  return (
                    <div
                      key={index}
                      className="cursor-pointer"
                      onClick={() => openImagesModal(property?.floor_plans, index)}
                    >
                      <img src={image} alt="carousel" style={{ maxHeight: 300, width: 'auto' }} />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="pt-4">
          <SecondaryButton className="ml-auto" onClick={toggleExpansion}>
            {expanded ? <ArrowUpRoundIcon /> : <ArrowDownRoundIcon />}
            <span className="pl-2.5"> Read {expanded ? 'less' : 'more'} </span>
          </SecondaryButton>
        </div>
      </div>
    </>
  );
};

export default React.memo(PropertyDetails);
