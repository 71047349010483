import numeral from 'numeral';
import 'numeral/locales/en-gb';
import { apiGetTransactionCount } from '../../../../api/transactions';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import TransactionChart from '../Charts/TransactionChart';
import moment from 'moment';
import Select from 'react-select';

import { regionOptions } from '../../../../utils/helpers';
numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const TransactionCountChart = ({ region = {}, transactionCount, isLoading }) => {
  const label = region.label;
  const [seriesData, setSeriesData] = useState([]);

  useEffect(() => {
    if (transactionCount) {
      const formattedData = transactionCount[region.value]
        .sort((a, b) => a.timestamp.localeCompare(b.timestamp))
        .filter(({ timestamp }) => moment(timestamp).isAfter(moment().subtract(5, 'years'), 'month', '(]'));

      setSeriesData(formattedData || []);
    }
  }, [region.value, transactionCount]);

  return (
    <div className="relative">
      <TransactionChart seriesData={seriesData} label={label} loading={isLoading} />

      {isLoading ? (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div style={{ height: '350px' }} className="flex flex-col	justify-center items-center">
            <LoaderSvg className="animate-spin h-16 w-16 text-white mx-auto my-8" />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const TransactionCount = () => {
  const [region, setRegion] = useState(regionOptions[0]);

  const { data: transactionCount, isLoading } = useQuery(['transactionCount'], ({ signal }) =>
    apiGetTransactionCount(signal),
  );

  return (
    <>
      <h4 className="mb-2.5 ml-2 fs-20 text-black"> Sales transaction count </h4>
      <div className="card">
        <div className="card-body pb-0 px-sm-3 px-0 pt-4">
          <div className="pb-0 m-3 flex justify-start items-center">
            <div className="form-group flex flex-col w-1/3 mx-2">
              <label className="font-medium text-black text-xs"> Post Town </label>
              <Select
                className="text-xs min-h-10"
                key="selectedIndex"
                value={regionOptions.find((option) => option.value === region.value)}
                onChange={(item) => setRegion(item)}
                options={regionOptions}
                isSearchable={true}
              />
            </div>
          </div>

          <TransactionCountChart region={region} transactionCount={transactionCount} isLoading={isLoading} />
        </div>
      </div>
    </>
  );
};

export default TransactionCount;
