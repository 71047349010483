import React from 'react';
import ReactApexChart from 'react-apexcharts';
import * as numeral from 'numeral';
import 'numeral/locales/en-gb';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const calculateGrowth = (value, rate) => {
  return value * (1 + rate);
};

const formatSeriesData = ({ forecastData, dataType, extendedData }) => {
  if (forecastData && forecastData.length) {
    const formattedForecast = forecastData.map((item) => {
      let timestamp = new Date(item.timestamp);
      let value = dataType === 'rate' ? item.value : parseInt(item.value);
      return [timestamp, value];
    });

    if (extendedData) {
      let lastEntry = formattedForecast[formattedForecast.length - 1];
      let lastValue = lastEntry[1];
      let lastDate = new Date(lastEntry[0]);

      for (let year = 1; year <= 15; year++) {
        let nextYear = new Date(lastDate.setFullYear(lastDate.getFullYear() + 1));
        lastValue = calculateGrowth(lastValue, 0.025);
        let resultValue = dataType === 'rate' ? lastValue : parseInt(lastValue);
        formattedForecast.push([nextYear, resultValue]);
      }
    }

    return formattedForecast;
  } else {
    return [];
  }
};

const SimpleLineChart = (props) => {
  const forecastDataA = props.itemGraphSeriesData;
  const dataType = props?.dataType || '';
  const extendedData = props.extendedData;

  const seriesData = [
    {
      name: props.address,
      type: 'area',
      data: formatSeriesData({ forecastData: forecastDataA, dataType, extendedData }),
    },
  ];

  const options = {
    chart: {
      id: 'price-chart',
      height: 150,
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
    },
    tooltip: {
      enabled: false,
    },
    forecastDataPoints: {
      count: 23,
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      colors: ['#3571E5', '#ff4560', '#705fd3'],
      strokeColors: ['#3571E5', '#ff4560', '#705fd3'],
    },
    legend: {
      show: false,
    },
    stroke: {
      width: 2,
      curve: 'smooth',
    },
    grid: {
      show: false,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0,
        opacityFrom: 0.7,
        opacityTo: 0.01,
      },
    },
    xaxis: {
      type: 'datetime',
      show: false,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },

    yaxis: {
      tickAmount: 0,
      lines: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    colors: ['#3571E5', '#ff4560', '#705fd3'],
  };

  return (
    <>
      <div id="chart2">
        <ReactApexChart options={options} type="area" series={seriesData} height={150} />
      </div>
    </>
  );
};

export default SimpleLineChart;
