import { useState } from 'react';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { apiGetPropertyAnalytics } from '../../../api/property';
import { ReactComponent as ArrowDown } from 'icons/custom/arrow-blue.svg';
import { ReactComponent as BackSVG } from 'icons/custom/arrow-back.svg';

import Listings from './Components/Listings';
import Comparables from './Components/Comparables';
import { useQuery } from '@tanstack/react-query';

const PropertyComparables = () => {
  const { propertyId } = useParams();
  const [isListingsOpen, setIsListingsOpen] = useState(true);

  const toggleAccordion = () => {
    setIsListingsOpen((isListingsOpen) => !isListingsOpen);
  };

  const { data: property } = useQuery(['property-analytics', propertyId], ({ signal }) =>
    apiGetPropertyAnalytics(propertyId, signal),
  );

  return (
    <>
      <div>
        <div className="flex flex-wrap items-center justify-start">
          <Link className="text-primary flex items-center mb-2" to={`/property-analytics/${property?.id}`}>
            <BackSVG className="mr-2 h-4 w-4" />
            <span className="pl-2 text-gray-500 fs-14"> Back to Property analytics </span>
          </Link>
        </div>
        <h4 className="fs-20"> Refine comparables </h4>
      </div>
      {property && property.id && (
        <div className="card p-0 mt-4">
          <div className="p-4">
            <Comparables property={property} />
          </div>
          <div onClick={toggleAccordion} className="flex items-center cursor-pointer px-4 pb-3">
            <ArrowDown className={`w-5 mr-2 ${isListingsOpen ? 'fa-rotate-0' : 'fa-rotate-180'}`} />
            <h4 className="text-base text-black"> Listings </h4>
          </div>

          {isListingsOpen && (
            <div className="p-4" style={{ backgroundColor: '# ' }}>
              <Listings property={property}></Listings>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PropertyComparables;
