import { useCallback, useMemo, useState } from 'react';
import { getListingsCSV, getPropertyListings } from '../../../../api/listings';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';
import { ReactComponent as DownloadSvg } from 'icons/custom/download.svg';
import { ReactComponent as InfoBlue } from 'icons/custom/info-icon-blue.svg';
import { ReactComponent as MapActive } from 'icons/custom/map-view-active.svg';

import ListingTable from '../../Shared/ListingTable';
import _ from 'lodash';

import axios from 'axios';
import SecondaryButton from '../../Dashboard/Buttons/SecondaryButton';
import FilterListings from './FilterListings';
import PrimaryButton from '../../Dashboard/Buttons/PrimaryButton';
import { createProperty } from '../../../../api/property';
import PropertyGenerationStatusModal from '../../Dashboard/Components/PropertyGenerationStatusModal';
import { propertySVGs } from '../../../../utils/helpers';
import moment from 'moment';
import InfoAsterisk from '../../Dashboard/Components/InfoAsterisk';
import MapView from '../../Shared/MapView';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import StatusModal from '../../Dashboard/Modals/StatusModal';
import { getPropertyIndicesbyUPRN } from '../../../../api/propertyIndex';

const Listings = ({ property }) => {
  const [mapViewActive, setMapViewActive] = useState(true);
  const [listingsData, setListingsData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [controlledSelectedRows, setControlledSelectedRows] = useState();
  const [selectedListings, setSelectedListings] = useState([]);
  const [valuationInProgress, setValuationInProgress] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [csvLoading, setCsvLoading] = useState(false);
  const [filterData, setFilterData] = useState({
    latitude: property.latitude,
    longitude: property.longitude,
    ascending: false,
    order_by: 'address',
    max_distance: 10,
    min_distance: 0,
    district_code: property?.property_index_data?.district_code,
    post_town: property?.property_index_data?.post_town,
    number_of_bedrooms: [parseInt(property?.property_index_data?.number_of_bedrooms)],
    property_type: [property?.property_index_data?.property_type],
    offset: 0,
    limit: 10,
  });

  const [statusModalContent, setStatusModalContent] = useState({
    type: '',
    description: '',
  });

  const {
    data: listingsResult,
    isLoading,
    isSuccess: listingsLoaded,
  } = useQuery(['listings', property.id, filterData, selectedListings], ({ signal }) =>
    getPropertyListings(
      {
        ...filterData,
        min_date: moment().subtract(3, 'months').format('YYYY-MM-DD'),
      },
      signal,
    ),
  );

  const queryClient = useQueryClient();

  // eslint-disable-next-line no-unused-vars
  const listingsDataMemoized = useMemo(() => {
    if (listingsLoaded) {
      setListingsData(listingsResult?.data);
      setPageCount(Math.ceil(listingsResult?.total_count / 10));
      const selectedRowsList = {};
      listingsResult?.data.forEach((item, index) => {
        if (selectedListings.find((listing) => listing?.uprn === item.uprn)) {
          selectedRowsList[index] = true;
        }
      });
      setControlledSelectedRows(selectedRowsList);
      return listingsResult || {};
    } else {
      return {};
    }
  }, [listingsLoaded, listingsResult, selectedListings]);

  let cancelToken;
  const downloadCSV = async () => {
    setCsvLoading(true);
    if (cancelToken) {
      cancelToken.cancel('Operation cancelled due to new Request');
    }
    cancelToken = axios.CancelToken.source();
    try {
      await getListingsCSV(
        property.id,
        {
          ...filterData,
          order_by: 'date',
          ascending: false,
          offset: 0,
          limit: 100,
          min_date: moment().subtract(3, 'months').format('YYYY-MM-DD'),
        },
        { cancelToken: cancelToken.token },
      );

      setCsvLoading(false);
    } catch (error) {
      setCsvLoading(false);
      setStatusModalContent({
        show: true,
        type: 'error',
        description: 'Error occurred, please try again later',
      });
    }
  };

  const updateFilter = (formData) => {
    setFilterData((prevFilterData) => {
      return {
        ...prevFilterData,
        ...formData,
      };
    });
    setPageIndex(0);
    setSelectedListings([]);
  };

  const fetchData = useCallback(
    ({ pageIndex, sortBy }) => {
      let formData = { ...filterData };
      formData.offset = 10 * pageIndex || 0;

      if (sortBy && sortBy.length) {
        formData.ascending = sortBy[0].desc;
        formData.order_by = sortBy[0].id;
      }

      setPageIndex(pageIndex);
      setFilterData(formData);
    },
    [filterData],
  );

  const onSelectedRowsChange = useCallback(
    ({ selectedRowIds }) => {
      let indexArrayInt = Object.keys(selectedRowIds)
        .filter((key) => selectedRowIds[key])
        .map((key) => parseInt(key));

      const selectedProperties = listingsData
        .filter((_, index) => indexArrayInt.includes(index))
        .map((listing) => listing);

      const excludedPropertyUprns = listingsData
        .filter((_, index) => !indexArrayInt.includes(index))
        .map((listing) => listing.uprn);

      const uniqueValues = _.unionBy(selectedListings, selectedProperties, 'uprn');
      const resultArray = uniqueValues.filter((item) => !excludedPropertyUprns.includes(item.uprn));

      setSelectedListings(resultArray);
    },
    [listingsData, selectedListings],
  );

  const generateValuation = async () => {
    setValuationInProgress(true);
    try {
      const updatedListings = [];
      for (const property of selectedListings) {
        const indicesResponse = await getPropertyIndicesbyUPRN(property.uprn);
        const forecastExists = indicesResponse?.data?.data[0]?.forecast !== undefined;

        const updatedProperty = {
          address: property.address,
          district_code: property.district_code,
          latitude: property.latitude,
          longitude: property.longitude,
          num_rooms: property.number_of_bedrooms,
          post_town: property.post_town,
          property_type: property.property_type,
          epc: property.current_energy_rating_epc,
          uprn: property.uprn.toString(),
          custom: !forecastExists,
        };
        updatedListings.push(updatedProperty);
      }

      const promises = updatedListings.map((property) => createProperty(property));
      const results = await Promise.all(promises);
      if (results) {
        queryClient.invalidateQueries(['dashboardData']);
        queryClient.invalidateQueries(['rawProperties']);
        queryClient.invalidateQueries(['rawProperties', 'in-progress']);
        setShowStatusModal(true);
      }
      setValuationInProgress(false);
    } catch (error) {
      setValuationInProgress(false);
      setStatusModalContent({
        show: true,
        type: 'error',
        description: 'Error occurred, please try again later',
      });
    }
  };

  const toggleProperty = useCallback(
    (propertyId) => {
      const selectedProperties = [...selectedListings];
      const index = selectedProperties.findIndex((property) => property.property_id === propertyId);

      if (index !== -1) {
        selectedProperties.splice(index, 1);
      } else {
        const currentProperty = listingsData.find((property) => property.property_id === propertyId);
        selectedProperties.push(currentProperty);
      }
      setSelectedListings(selectedProperties);
    },
    [listingsData, selectedListings],
  );

  return (
    <>
      <StatusModal
        setShowModal={() => setStatusModalContent({ show: false })}
        showModal={statusModalContent.show}
        content={statusModalContent}
      />
      <PropertyGenerationStatusModal
        setShowModal={setShowStatusModal}
        showModal={showStatusModal}
        selectedProperties={selectedListings.length}
      />
      <div className="row">
        <div className="col-md-6 col-lg-4 py-2 pl-3">
          <div className="flex items-center mb-3">
            <InfoBlue className="w-4 mr-2" />
            <span className="text-xs">
              {property?.property_index_data?.number_of_bedrooms}-bedroom{' '}
              {propertySVGs[property?.property_index_data?.property_type]?.name?.toLowerCase()}
              {property?.property_index_data?.property_type === 'F' ? 's' : ' houses '} listed nearby within the last 3
              months
            </span>
          </div>
          <div className="flex items-center">
            <div
              className="mr-4 p-1.5 pl-3 rounded-lg flex items-center"
              style={{
                background: '#EDF2F6',
                color: '#A4ABBA',
              }}
            >
              Properties selected for valuation
              <div
                className="rounded-lg px-2 py-1 ml-2"
                style={{
                  background: '#3571E526',
                  color: '#3571E5',
                }}
              >
                {selectedListings.length}
              </div>
            </div>
            <PrimaryButton onClick={() => setMapViewActive(!mapViewActive)} className="mr-4">
              <MapActive className="mr-3" />
              {mapViewActive ? 'Hide Map' : 'Show Map'}
            </PrimaryButton>
            {isLoading && <LoaderSvg className="animate-spin h-8 w-8 text-white" />}
          </div>
        </div>
        <div className="col-md-6 col-lg-4">
          <FilterListings updateFilter={(params) => updateFilter(params)} />
        </div>
      </div>
      {mapViewActive ? (
        <MapView
          mainProperty={property}
          properties={listingsData}
          filteredIds={[]}
          selectedProperties={selectedListings}
          toggleProperty={toggleProperty}
          loading={isLoading}
          listingMode={true}
        />
      ) : (
        ''
      )}
      <ListingTable
        listings={listingsData}
        onSelectedRowsChange={onSelectedRowsChange}
        controlledSelectedRows={controlledSelectedRows}
        pageCount={pageCount}
        pageIndex={pageIndex}
        fetchData={fetchData}
        loading={isLoading}
      />
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <div className="mr-2">
            <InfoAsterisk
              text={
                <div className="text-gray-500 py-1">Downloaded CSV file will include the most recent 100 listings</div>
              }
              color="text-white"
              placement="top"
              icon={<InfoBlue className="w-4" />}
            />
          </div>

          <SecondaryButton className="w-36 mr-3" onClick={() => downloadCSV()}>
            <DownloadSvg className="mr-1.5" />
            Download CSV
          </SecondaryButton>
          {csvLoading && <LoaderSvg className="animate-spin h-8 w-8 text-white mr-2" />}
        </div>

        <div className="flex items-center">
          {valuationInProgress && <LoaderSvg className="animate-spin h-8 w-8 text-white" />}
          <PrimaryButton
            className={'mr-2 ml-2 px-3 w-auto'}
            onClick={() => generateValuation()}
            disabled={valuationInProgress || !(selectedListings.length > 0)}
          >
            Generate valuation
          </PrimaryButton>
        </div>
      </div>
    </>
  );
};

export default Listings;
