export const GOOGLE_MAPS_API_KEY = 'AIzaSyCLZBiI1Qvx96yu-MpK-msHtAgBst6CjUM';

export const numberOfBedroomsOptions = [
  { value: undefined, label: ' All ' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
];

export const propertyTypeOptions = [
  { value: undefined, label: ' All ' },
  { value: 'F', label: 'Flat' },
  { value: 'T', label: 'Terraced' },
  { value: 'S', label: 'Semi-Detached' },
  { value: 'D', label: 'Detached' },
];

export const numberOfBedroomsOptionsWithAll = [
  { value: 'all', label: ' All ' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
];

export const propertyTypeOptionsWithAll = [
  { value: 'all', label: ' All ' },
  { value: 'F', label: 'Flat' },
  { value: 'T', label: 'Terraced' },
  { value: 'S', label: 'Semi-Detached' },
  { value: 'D', label: 'Detached' },
];

export const numberOfBedroomsOptionsOnly = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
];

export const epcOptionsWithAll = [
  { value: undefined, label: ' All ' },
  { value: 'A', label: 'A' },
  { value: 'B', label: 'B' },
  { value: 'C', label: 'C' },
  { value: 'D', label: 'D' },
  { value: 'E', label: 'E' },
  { value: 'F', label: 'F' },
  { value: 'G', label: 'G' },
];

export const epcOptions = [
  { value: 'A', label: 'A' },
  { value: 'B', label: 'B' },
  { value: 'C', label: 'C' },
  { value: 'D', label: 'D' },
  { value: 'E', label: 'E' },
  { value: 'F', label: 'F' },
  { value: 'G', label: 'G' },
];

export const propertyTypeOptionsOnly = [
  { value: 'F', label: 'Flat' },
  { value: 'T', label: 'Terraced' },
  { value: 'S', label: 'Semi-Detached' },
  { value: 'D', label: 'Detached' },
];
export const ViewModes = {
  GRID: 'grid',
  LIST: 'list',
};

export const comparablesViewModes = {
  MAP: 'map',
  LIST: 'list',
};

export const customSelectStyles = {
  option: (provided, state) => {
    return {
      ...provided,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      background: state.isFocused ? '#F3F5F9' : '#FFFFFF',
      borderWidth: '0px 1px 1px 1px',
      borderStyle: 'solid',
      borderColor: '#E6E8EC',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '400',
      color: '#747B8A',
    };
  },
  placeholder: (provided) => ({
    ...provided,
    color: '#747B8A',
  }),
  valueContainer: (provided) => ({
    ...provided,
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
  }),
  indicatorContainer: (provided) => ({
    ...provided,
  }),
  control: (provided) => ({
    ...provided,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    color: '#747B8A',
    border: '1px solid #F0F0F0',
    height: '100%',
  }),
};

export const regionIndexKeys = [
  { key: 'SCOTLAND Region', mapId: 'scotland', name: 'Scotland' },
  { key: 'WEST MIDLANDS Region', mapId: 'west-midlands', name: 'West Midlands' },
  { key: 'SOUTH WEST Region', mapId: 'south-west', name: 'South West' },
  { key: 'NORTH WEST Region', mapId: 'north-west', name: 'North West' },
  { key: 'SOUTH EAST Region', mapId: 'south-east', name: 'South East' },
  { key: 'GREATER LONDON Region', mapId: 'london', name: 'Greater London' },
  { key: 'NORTHERN IRELAND Region', mapId: 'northern-ireland', name: 'Northern Ireland' },
  { key: 'WALES Region', mapId: 'wales', name: 'Wales' },
  { key: 'EAST MIDLANDS Region', mapId: 'east-midlands', name: 'East Midlands' },
  { key: 'NORTH EAST Region', mapId: 'north-east', name: 'North East' },
  { key: 'EAST ENGLAND Region', mapId: 'east-england', name: 'East England' },
  { key: 'YORKSHIRE AND HUMBER Region', mapId: 'yorkshire-and-humber', name: 'Yorkshire and the Humber' },
];
