import { useCallback, useMemo, useState } from 'react';

import MapView from '../../Shared/MapView';
import ListView from './ListView';

import { getPropertyComparables, getPropertyComparablesCSV } from '../../../../api/comparables';
import PrimaryButton from '../../Dashboard/Buttons/PrimaryButton';
import { createProperty } from '../../../../api/property';
import { ReactComponent as InfoBlue } from 'icons/custom/info-icon-blue.svg';
import { ReactComponent as DownloadSvg } from 'icons/custom/download.svg';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';
import { ReactComponent as MapActive } from 'icons/custom/map-view-active.svg';
import PropertyGenerationStatusModal from '../../Dashboard/Components/PropertyGenerationStatusModal';
import InfoAsterisk from '../../Dashboard/Components/InfoAsterisk';
import axios from 'axios';
import SecondaryButton from '../../Dashboard/Buttons/SecondaryButton';
import FilterComparables from './FilterComparables';
import { useQuery } from '@tanstack/react-query';
import StatusModal from '../../Dashboard/Modals/StatusModal';
import OptimisedCompsList from './OptimisedCompsList';
import DeleteOptimalComparableModal from './DeleteOptimalComparableModal';

const Comparables = ({ property }) => {
  const [mapViewActive, setMapViewActive] = useState(true);
  const [comparablesList, setComparablesList] = useState([]);

  const [comparablesTotalCount, setComparablesTotalCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [controlledSelectedRows, setControlledSelectedRows] = useState();
  const [csvLoading, setCsvLoading] = useState(false);
  const [revalueInProgress, setRevalueInProgress] = useState(false);

  const [optimalComparablesList, setOptimalComparablesList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [propertyToDelete, setPropertyToDelete] = useState('');

  const [filterData, setFilterData] = useState({
    bathrooms: [],
    current_energy_rating_epc: [],
    distance_from: null,
    freehold_leasehold: null,
    limit: 10,
    min_size: 0,
    max_size: 2700,
    min_price: property?.price ? Math.round(property?.price * 0.7) : 0,
    max_price: property?.price ? Math.round(property?.price * 1.3) : 10000000,
    null_toggle: true,
    offset: 0,
    old_new_lr: null,
    filtered_ids: [],
  });

  const [statusModalContent, setStatusModalContent] = useState({
    type: '',
    description: '',
  });

  const {
    data: comparablesData,
    isLoading,
    isSuccess: comparablesLoaded,
  } = useQuery(['comparables', property.id, filterData], ({ signal }) =>
    getPropertyComparables(
      property.id,
      {
        order_by: 'date',
        ascending: false,
        ...filterData,
        max_price: filterData?.max_price < 10000000 ? filterData?.max_price : null,
        max_size: filterData?.max_size < 2700 ? filterData?.max_size : null,
        min_price: filterData?.min_price ? filterData?.min_price : null,
        min_size: filterData?.min_size ? filterData?.min_size : null,
        filtered_ids: filterData.filtered_ids,
        offset: filterData?.offset || 0,
        limit: filterData?.limit || 10,
      },
      signal,
    ),
  );

  const comparablesResult = useMemo(() => {
    if (comparablesLoaded) {
      setComparablesTotalCount(comparablesData?.total_count);

      const selectedRowsList = {};
      comparablesData?.data.forEach((item, index) => {
        selectedRowsList[index] = !filterData?.filtered_ids?.includes(item?.id);
      });

      const comparables = comparablesData?.data;

      const optimalIds = new Set(optimalComparablesList.map((item) => item.id));

      comparables.forEach((item) => {
        if (optimalIds.has(item.id)) {
          item.optimalComparable = true;
        }
      });

      setComparablesList(comparables);

      setControlledSelectedRows(selectedRowsList);
      return comparablesData || {};
    } else {
      return {};
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    comparablesLoaded,
    comparablesData,
    optimalComparablesList,
    optimalComparablesList.length,
    filterData?.filtered_ids,
  ]);

  const { data: optimalComparablesData, isSuccess: optimalComparablesLoaded } = useQuery(
    ['comparables', property.id, 'optimal'],
    ({ signal }) =>
      getPropertyComparables(
        property.id,
        {
          order_by: 'distance_from',
          dataset: 'LR',
          ascending: true,
          max_price: property?.price ? Math.round(property?.price * 1.1) : 10000000,
          min_price: property?.price ? Math.round(property?.price * 0.9) : 0,
          offset: 0,
          limit: 5,
        },
        signal,
      ),
  );

  const optimalComparablesResult = useMemo(() => {
    if (optimalComparablesLoaded) {
      setOptimalComparablesList(optimalComparablesData?.data);
      return optimalComparablesData || {};
    } else {
      return {};
    }
  }, [optimalComparablesLoaded, optimalComparablesData]);

  const updateFilter = (formData) => {
    setFilterData({ ...formData, filtered_ids: [] });
    setPageIndex(0);
  };

  const revalueProperty = async () => {
    setRevalueInProgress(true);
    try {
      const propertyParams = {
        uprn: property?.uprn,
        parent_id: property?.id,
        address: property?.address,
        property_type: property?.property_index_data?.property_type,
        num_rooms: Number(property?.property_index_data?.number_of_bedrooms),
        number_of_bedrooms: Number(property?.property_index_data?.number_of_bedrooms),
        area_code: property?.property_index_data?.area_code,
        district_code: property?.property_index_data?.district_code,
        post_code: property?.address?.split(' ').pop(),
        post_town: property?.property_index_data?.post_town,
        custom: true,
        latitude: property?.latitude,
        longitude: property?.longitude,
        epc: property?.epc,
        comparables: {
          ...filterData,
          offset: 0,
          limit: comparablesTotalCount,
          property_id: property?.id,
        },
      };

      const newPropertyResponse = await createProperty(propertyParams);
      if (newPropertyResponse) {
        setShowStatusModal(true);
      }
      setRevalueInProgress(false);
    } catch (error) {
      setRevalueInProgress(false);
      setStatusModalContent({
        show: true,
        type: 'error',
        description: 'Error occurred, please try again later',
      });
    }
  };

  let cancelToken;
  const downloadCSV = async () => {
    setCsvLoading(true);
    if (cancelToken) {
      cancelToken.cancel('Operation cancelled due to new Request');
    }
    cancelToken = axios.CancelToken.source();
    try {
      await getPropertyComparablesCSV(
        property.id,
        {
          ...filterData,
          max_price: filterData?.max_price < 10000000 ? filterData?.max_price : null,
          max_size: filterData?.max_size < 2700 ? filterData?.max_size : null,
          min_price: filterData?.min_price ? filterData?.min_price : null,
          min_size: filterData?.min_size ? filterData?.min_size : null,
          order_by: 'date',
          ascending: false,
          offset: 0,
          limit: 100,
        },
        { cancelToken: cancelToken.token },
      );

      setCsvLoading(false);
    } catch (error) {
      setCsvLoading(false);
      setStatusModalContent({
        show: true,
        type: 'error',
        description: 'Error occurred, please try again later',
      });
    }
  };

  const fetchData = useCallback(
    ({ pageIndex, sortBy }) => {
      let formData = { ...filterData };
      formData.offset = 10 * pageIndex || 0;

      if (sortBy && sortBy.length) {
        formData.ascending = sortBy[0].desc;
        formData.order_by = sortBy[0].id;
      }
      setPageIndex(pageIndex);
      setFilterData(formData);
    },
    [filterData],
  );

  const onSelectedRowsChange = useCallback(
    ({ selectedRowIds }) => {
      const indexArrayInt = [];
      for (const key of Object.keys(selectedRowIds).filter((key) => selectedRowIds[key])) {
        indexArrayInt.push(parseInt(key));
      }
      const filteredComparableIds = comparablesList
        .filter((_, index) => !indexArrayInt.includes(index))
        .map((comparable) => comparable.id);
      const includedComparableIds = comparablesList
        .filter((_, index) => indexArrayInt.includes(index))
        .map((comparable) => comparable.id);

      const uniqueValues = Array.from(new Set([...filterData.filtered_ids, ...filteredComparableIds]));
      const resultArray = uniqueValues.filter((item) => !includedComparableIds.includes(item));

      setFilterData((prevValue) => {
        return { ...prevValue, filtered_ids: resultArray };
      });
    },
    [comparablesList, filterData],
  );

  const toggleProperty = useCallback(
    (propertyId) => {
      const filteredIds = filterData.filtered_ids;
      const index = filteredIds.indexOf(propertyId);
      if (index !== -1) {
        filteredIds.splice(index, 1);
      } else {
        filteredIds.push(propertyId);
      }

      setFilterData((prevValue) => {
        return { ...prevValue, filtered_ids: filteredIds };
      });
    },
    [filterData],
  );

  const onToggleFavourite = useCallback((property) => {
    setOptimalComparablesList((prevList) => {
      if (prevList.some((item) => item.id === property.id)) {
        return prevList.filter((item) => item.id !== property.id);
      } else if (prevList.length < 5) {
        return [...prevList, property];
      } else {
        return [...prevList];
      }
    });
  }, []);

  const toggleFavourite = (property) => {
    if (optimalComparablesList.some((item) => item.id === property.id)) {
      setPropertyToDelete(property);
      setShowDeleteModal(true);
    } else {
      onToggleFavourite(property);
    }
  };

  return (
    <>
      <DeleteOptimalComparableModal
        setShowModal={setShowDeleteModal}
        showModal={showDeleteModal}
        property={propertyToDelete}
        handleSuccess={(property) => onToggleFavourite(property)}
      />

      <StatusModal
        setShowModal={() => setStatusModalContent({ show: false })}
        showModal={statusModalContent.show}
        content={statusModalContent}
      />
      <PropertyGenerationStatusModal setShowModal={setShowStatusModal} showModal={showStatusModal} />
      <div>
        <FilterComparables updateFilter={(params) => updateFilter(params)} property={property} />

        <div className="flex justify-between">
          <div className="flex items-center">
            <div
              className="mr-4 p-1.5 pl-3 rounded-lg flex items-center"
              style={{
                background: '#F3F6FB',
                color: '#A4ABBA',
              }}
            >
              Properties selected as comparables
              <div
                className="rounded-lg px-2 py-1 ml-2"
                style={{
                  background: '#3571E526',
                  color: '#3571E5',
                }}
              >
                {(comparablesTotalCount || 0) - filterData.filtered_ids?.length > 0
                  ? (comparablesTotalCount || 0) - filterData.filtered_ids?.length
                  : 0}
              </div>
            </div>
            <PrimaryButton onClick={() => setMapViewActive(!mapViewActive)} className="mr-4">
              <MapActive className="mr-3" />
              {mapViewActive ? 'Hide Map' : 'Show Map'}
            </PrimaryButton>
            {isLoading && <LoaderSvg className="animate-spin h-8 w-8 text-white" />}
          </div>

          <div className="flex items-center">
            {csvLoading && <LoaderSvg className="animate-spin h-8 w-8 text-white mr-2" />}

            <div className="mr-2">
              <InfoAsterisk
                text={
                  <div className="text-gray-500 py-1">
                    Downloaded CSV file will include the most recent 100 comparables
                  </div>
                }
                color="text-white"
                placement="top"
                icon={<InfoBlue className="w-4" />}
              />
            </div>
            <SecondaryButton className="w-36 mr-3" onClick={() => downloadCSV()}>
              <DownloadSvg className="mr-1.5" />
              Download CSV
            </SecondaryButton>
          </div>
        </div>

        {mapViewActive ? (
          <MapView
            mainProperty={property}
            properties={comparablesList}
            filteredIds={filterData.filtered_ids}
            toggleProperty={toggleProperty}
            loading={isLoading}
          />
        ) : (
          ''
        )}

        {optimalComparablesResult ? (
          <OptimisedCompsList
            property={property}
            optimalComparables={optimalComparablesList}
            toggleFavourite={toggleFavourite}
          />
        ) : (
          ''
        )}

        <div className="p-3" style={{ background: '#F7F9FC' }}>
          <ListView
            optimalComparables={optimalComparablesList}
            toggleFavourite={toggleFavourite}
            comparables={comparablesList}
            onSelectedRowsChange={onSelectedRowsChange}
            controlledSelectedRows={controlledSelectedRows}
            pageCount={Math.ceil(comparablesTotalCount / 10)}
            pageIndex={pageIndex}
            fetchData={fetchData}
            loading={isLoading}
          />

          <div className="flex justify-end">
            <div className="flex items-center">
              {!(comparablesResult && comparablesResult?.message === 'valid') ||
              (comparablesTotalCount || 0) - filterData.filtered_ids?.length <= 0 ? (
                <div className="mr-2">
                  <InfoAsterisk
                    text={
                      <div className="text-gray-500 py-1">
                        More comparables are required in order to revalue your property. Select some more and try again.
                      </div>
                    }
                    color="text-white"
                    placement="top"
                    icon={<InfoBlue className="w-4" />}
                  />
                </div>
              ) : (
                ''
              )}

              <div className="flex items-center">
                {revalueInProgress && <LoaderSvg className="animate-spin h-8 w-8 text-white" />}
                <PrimaryButton
                  className={'mr-2 ml-2 px-5 w-auto '}
                  onClick={() => revalueProperty()}
                  disabled={
                    revalueInProgress ||
                    !(comparablesResult && comparablesResult?.message === 'valid') ||
                    (comparablesTotalCount || 0) - filterData.filtered_ids?.length <= 0
                  }
                >
                  Revalue
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Comparables;
